import { createContext, useContext, useEffect, useState } from "react";
import Medusa from "@medusajs/medusa-js";
import { server_url, WEBSITE_REGION_NAME } from "../../environmentVariables/env";
import useLocalStorage from "../../hooks/useLocalStorage";

const medusa_baseUrl = server_url;

const MedusaContext = createContext();


export const useMedusa = () => useContext(MedusaContext);


export const MedusaProvider = ({ children }) => {
    const [medusa, setMedusa] = useState(null);
    const [region, setRegion] = useState(null);
    const [user, setUser] = useState(null);
    const [cart, setCart] = useState(null);
    const [cartId, setCartId] = useLocalStorage("cart_id", "");
    const [wishlist, setWishlist] = useState([]);
    const [swapActive, setSwapActive] = useState(false);
    const [swap, setSwap] = useState({
        order_id: "",
        form_details: {
            reason: {},
            condition: {},
            outcome: {},
            additional_info: "",
            file: null,
            items: []
        },
        return_items: [],
        additional_items: [],
    });
    const [swapCart, setSwapCart] = useState([]);

    const [shoppingTags, setShoppingTags] = useState([]);

    useEffect(() => {
        if (medusa != null) return;
        initializeClient();
    }, []);

    useEffect(() => {
        if (!medusa) return;
        initializeRegion();
        refetchUser();
    }, [medusa]);

    useEffect(() => {
        if (!user) {
            setWishlist(() => null)
        } else {
            setWishlist(() =>
                user.metadata?.wishlist
            );
        }
    }, [user]);

    const initializeClient = async () => {
        try {
            const medusaInstance = new Medusa({
                maxRetries: 3,
                baseUrl: medusa_baseUrl,
            })
            setMedusa(() => medusaInstance);
        } catch (e) {
            console.error(e);
        }
    };

    const initializeRegion = async () => {
        try {
            await medusa.regions.list()
                .then(({ regions }) => {
                    // console.log(regions);
                    setRegion(() =>
                        regions.find(reg =>
                            reg.name.toLowerCase() === WEBSITE_REGION_NAME.toLowerCase()
                        )
                    )
                });

        } catch (e) {
            console.error(e);
        }
    }

    const refetchUser = async () => {
        try {
            await medusa.auth.getSession()
                .then(({ customer }) => {
                    setUser(customer)
                })
                .catch(err => err)
        } catch (e) {
            console.error(e);
        }
    }

    useEffect(() => {
        if (!medusa) return;
        initializeCart();
    }, [medusa, user])

    const initializeCart = async () => {
        if (user && cart && (user?.metadata?.cart_id === cart?.id) && (user?.email === cart?.email)) {
            return;
        }
        try {
            // Check if user exists
            if (user) {
                // console.log("USER EXISTS")
                // HANDLE LOGGED IN USER
                setCartId(""); // We do not want default cart with no user
                // User is associated with an active cart
                if (user.metadata?.cart_id) {
                    // Fetch cart with the user.metadata.cart_id
                    let _cart = await medusa.carts.retrieve(user.metadata.cart_id)
                        .then(({ cart: _c }) => {
                            return _c
                        })
                        .catch(err => null)

                    if (!_cart) {
                        _cart = await medusa.carts.create()
                            .then(({ cart: _c }) => {
                                return _c
                            })

                        await medusa.customers.update({
                            metadata: { cart_id: _cart.id }
                        })
                            .then(({ customer }) => {
                                refetchUser();
                            })
                    }
                    setCart(_cart);
                    // console.log("USER.METADATA.CART_ID exists cart fetched and set");
                } else {
                    // User is not associated with an active cart
                    // Create a new Cart and update user's metadata.cart_id to the created cart
                    const newCart = await medusa.carts.create()
                        .then(({ cart: _cart }) => {
                            // console.log(cart.id);
                            setCart(_cart)
                            return _cart
                        });

                    await medusa.customers.update({
                        metadata: { cart_id: newCart.id }
                    })
                        .then(({ customer }) => {
                            // console.log(customer.id);
                            refetchUser();
                        });
                    // console.log("USER.METADATA.CART_ID does not exists created new cart and attached to loged in user")
                }
            } else {
                // HANDLE NO USER LOGGED IN
                // User does not exist
                // console.log("User does not exists");

                // ////////////////////////////////////////////////////////////////////////////////////////////
                // NEW LOGIC 
                // fetch cart with cartId
                // const existingCart = await medusa.carts.retrieve(cartId || "")
                //     .then(({ cart: _c }) => {
                //         // setCart(_cart)
                //         return _c;
                //     })
                //     .catch(e => null);

                // // If cart exists and has customer attached to it 
                // if (
                //     (existingCart && (existingCart.email || existingCart.customer_id)) || !existingCart
                // ) {
                //     if (!existingCart) {
                //         // console.log("cart does not EXISTS -> create new");
                //     } else {
                //         // console.log("CART EXISTS and customer exists for cart -> create new");
                //     }
                //     await medusa.carts.create()
                //         .then(({ cart: _cart }) => {
                //             // console.log(_cart);
                //             setCart(_cart)
                //             setCartId(_cart.id)
                //         });
                // } else {
                //     // console.log("CART EXISTS and customer does not exists for cart -> set existing cart");
                //     setCart(existingCart);
                // }
                // //////////////////////////////////////////////////////////////////////////////////////////////

                // /////////////////////////////////////////////////////////////////////////////////////////////
                // OLD LOGIC
                if (cartId) {
                    await medusa.carts.retrieve(cartId)
                        .then(({ cart: _c }) => {
                            setCart(_c)
                        });
                    // console.log("CART fetched with local storage.cart_id and set");
                } else {
                    await medusa.carts.create()
                        .then(({ cart: _cart }) => {
                            // console.log(_cart);
                            setCart(_cart)
                            setCartId(_cart.id)
                        });
                    // console.log("CART fetched with local storage.cart_id and set")
                }
                // //////////////////////////////////////////////////////////////////////////////////////////////
            }
        } catch (e) {
            console.error(e);
        }
    };

    // // Testing
    // useEffect(() => {
    //     console.log("CART/////////////", cart)
    // }, [cart])

    // useEffect(() => {
    //     console.log("REGION/////////////", region, WEBSITE_REGION_NAME);
    // }, [region])

    // useEffect(() => {
    //     console.log("USER/////////", user)
    // }, [user]);

    let value = {
        medusa,
        region,
        user,
        setUser: (_user) => setUser(_user),
        cart,
        setCart: (_cart) => setCart(_cart),
        setCartIdLocal: (_id) => setCartId(_id),
        wishlist,
        setWishlist: (_wishlist) => setWishlist(_wishlist),
        swap,
        setSwap: (val) => setSwap(val),
        swapActive,
        setSwapActive: (flag) => setSwapActive(flag),
        swapCart,
        setSwapCart: (_cart) => setSwapCart(_cart),
    };

    return (
        <MedusaContext.Provider value={value} >
            {children}
        </MedusaContext.Provider>
    )
}