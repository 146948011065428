import React from 'react';
import "./Spinner.css";

function Spinner({
    variant = "primary",
    color = "",
    size = "small"
}) {

    return (
        // <div 
        //     className="spinner-container border"
        //     style={size === "small" ? _size.small : size === "medium" ? _size.medium : _size.large}
        // >
        //     <div className="spinner" style={variant === "primary" ? _variant.primary : _variant.secondary}></div>
        // </div>
        <div class={`spinner-border ${color ? color : "text-light"} spinner-border-sm`} role="status">
            <span class="sr-only">Loading...</span>
        </div>
    )
}

export default Spinner