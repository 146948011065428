import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import clsx from "clsx";
import MenuCart from "./sub-components/MenuCart";
// import { useProduct } from "../../context/product/ProductContext";
// import { useUser } from "../../context/User/UserContext";
import { Fragment } from "react";
import { useMedusa } from "../../context/medusa/MedusaContext";
import cogoToast from "cogo-toast";
import { useEffect, useState } from "react";

const IconGroup = ({ iconWhiteClass }) => {
  const handleClick = e => {
    e.currentTarget.nextSibling?.classList?.toggle("active");
  };

  const triggerMobileMenu = () => {
    const offcanvasMobileMenu = document.querySelector(
      "#offcanvas-mobile-menu"
    );
    offcanvasMobileMenu?.classList?.add("active");
  };

  const {
    medusa,
    user,
    setUser,
    cart,
    setCart,
    wishlist,
    setWishlist
  } = useMedusa();

  const [cartSize, setCartSize] = useState(0);
  useEffect(() => {
    if (cart) {
      let items = 0;
      cart.items.forEach((item) => {
        if (item.variant.product.metadata?.hireable === true && item.variant.product.metadata?.security_deposit === true) {
          items += (item.quantity + 1);
        } else {
          items += item.quantity;
        }
      })
      setCartSize(items);
    }
  }, [cart])

  useEffect(() => {
    // HANDLE OUTER CLICK 
    // USER LOGO
    const account_dropdown = document.querySelector(".account-dropdown");
    document.body.addEventListener('click', function (e) {
      ;
      const closestContainer = e.target.closest('.account-dropdown');
      const iconButton = e.target.closest('.account-setting-active')
      if (!closestContainer && !iconButton) {
        account_dropdown?.classList?.remove("active");
      }
    });

    // CART
    // shopping-cart-content active
    const shopping_cart_content = document.querySelector(".shopping-cart-content");
    document.body.addEventListener('click', function (e) {
      ;
      const closestContainer = e.target.closest('.shopping-cart-content');
      const iconButton = e.target.closest('.icon-cart')
      if (!closestContainer && !iconButton) {
        shopping_cart_content?.classList?.remove("active");
      }
    });

    // SEARCH
    // search-content search-active
    const search_content = document.querySelector(".search-content");
    document.body.addEventListener('click', function (e) {
      ;
      const closestContainer = e.target.closest('.search-content');
      const iconButton = e.target.closest('.search-active')
      if (!closestContainer && !iconButton) {
        search_content?.classList?.remove("active");
      }
    });
  }, []);

  const handleLogoutUser = async () => {
    try {
      await medusa.auth.deleteSession()
        .then(() => {
          // customer logged out successfully
          setUser(null);
        })
      cogoToast.success("You have been successfully logged out. Thanks!");
    } catch (e) {
      cogoToast.error(e.message);
      console.error(e);
    }
  }

  return (
    <div className={clsx("header-right-wrap", iconWhiteClass)} >
      {/* <div className="same-style header-search d-none d-lg-block">
        <button className="search-active" onClick={e => handleClick(e)}>
          <i className="pe-7s-search" />
        </button>
        <div className="search-content">
          <form action="#">
            <input type="text" placeholder="Search" />
            <button className="button-search">
              <i className="pe-7s-search" />
            </button>
          </form>
        </div>
      </div> */}
      <div className="same-style account-setting d-none d-lg-block">
        <button
          className="account-setting-active"
          onClick={e => handleClick(e)}
        >
          <i className="pe-7s-user-female" />
        </button>
        <div className="account-dropdown">
          <ul>
            {!user ? <Fragment>
              <li>
                <Link to={"/login-register/login"}>
                  Login
                </Link>
              </li>
              <li>
                <Link to={"/login-register/register"}>
                  Register
                </Link>
              </li>
            </Fragment> :
              <Fragment>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/my-account"}>
                    My Account
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/my-orders"}>
                    My Orders
                  </Link>
                </li>
                <li onClick={handleLogoutUser} >
                  <Link to={process.env.PUBLIC_URL + "/login-register/login"}>
                    Log out
                  </Link>
                </li>
              </Fragment>}
          </ul>
        </div>
      </div>
      {/* <div className="same-style header-compare">
        <Link to={process.env.PUBLIC_URL + "/compare"}>
          <i className="pe-7s-shuffle" />
          <span className="count-style">
            {compareItems && compareItems.length ? compareItems.length : 0}
          </span>
        </Link>
      </div> */}
      <div className="same-style header-wishlist">
        <Link to={user ? process.env.PUBLIC_URL + "/wishlist" : process.env.PUBLIC_URL + "/login-register/login"}>
          <i className="pe-7s-like" />
          <span className="count-style">
            {wishlist && wishlist.length ? wishlist.length : 0}
          </span>
        </Link>
      </div>
      <div className="same-style cart-wrap d-none d-lg-block">
        <button className="icon-cart" onClick={e => handleClick(e)}>
          <i className="pe-7s-shopbag" />
          <span className="count-style">
            {cartSize}
          </span>
        </button>
        {/* menu cart */}
        <MenuCart />
      </div>
      <div className="same-style cart-wrap d-block d-lg-none">
        <Link className="icon-cart" to={process.env.PUBLIC_URL + "/cart"}>
          <i className="pe-7s-shopbag" />
          <span className="count-style">
            {cartSize}
          </span>
        </Link>
      </div>
      <div className="same-style mobile-off-canvas d-block d-lg-none">
        <button
          className="mobile-aside-button"
          onClick={() => triggerMobileMenu()}
        >
          <i className="pe-7s-menu" />
        </button>
      </div>
    </div>
  );
};

IconGroup.propTypes = {
  iconWhiteClass: PropTypes.string,
};



export default IconGroup;
