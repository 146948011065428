import { Fragment } from "react";
import { Link } from "react-router-dom";
import { useMedusa } from "../../../context/medusa/MedusaContext";
import CurrencyIcon from "../../icons/Icons";
import cogoToast from "cogo-toast";

const MenuCart = () => {
  const {
    medusa,
    user,
    region,
    cart,
    setCart,
    wishlist,
    setWishlist,
    swapActive
  } = useMedusa();

  const handleRemoveCartItem = async (cartItem) => {
    try {
      await medusa.carts.lineItems.delete(cart.id, cartItem.id)
        .then(({ cart: _cart }) => {
          setCart(_cart);
        })
      cogoToast.success("Product removed from cart");
    } catch (e) {
      cogoToast.error(e.message);
      console.error(e);
    }
  }

  return (
    <div className="shopping-cart-content">
      {cart && cart?.items?.length > 0 ? (
        <Fragment>
          <ul>
            {cart && cart?.items?.map((item, key) => {
              let itemTotal = Number(Math.floor(item?.original_total) / 100)?.toFixed(2);
              const isHireItem = Boolean(item.variant?.product?.metadata?.hireable);
              // const finalProductPriceHire = item.variant?.metadata?.pricingDetails ? Number(
              //   Math.floor(
              //     item.variant.metadata?.pricingDetails[region?.id]?.prices?.find(p => p.duration === "monthly")?.amount * item.quantity
              //   )
              // ) : 0;
              const isSecurityDepositEnabled = Boolean(item.variant?.product?.metadata?.security_deposit);
              const securityDepoThumbnailUrl = "https://app.breastisbest.ie/uploads/payload-refund.avif"
              const securityDepositPrice = item.metadata?.security_deposit ?
                Number(Math.floor(item.metadata?.securityDeposit?.metadata?.[region?.id]?.amount))
                : 0;
              const finalProductPrice = (item.total / 100 - securityDepositPrice);
              if (isHireItem) {
                return <Fragment>
                  <li className="single-shopping-cart" key={key}>
                    <div className="shopping-cart-img">
                      <Link to={process.env.PUBLIC_URL + "/product/" + item?.product?.id}>
                        <img
                          alt={item?.variant?.product?.title}
                          src={item?.variant?.product?.thumbnail}
                          className="img-fluid"
                        />
                      </Link>
                    </div>
                    <div className="shopping-cart-title">
                      <h4>
                        <Link
                          to={process.env.PUBLIC_URL + "/product/" + item?.variant?.product?.id}
                        >
                          {" "}
                          {item?.variant?.product?.title}
                          {" "}
                        </Link>
                      </h4>
                      {/* {item?.variant ? (
                      <div className="cart-item-variation">
                        <span>
                          {item?.variant?.title}
                        </span>
                      </div>
                    ) : (
                      ""
                    )} */}
                      <h6>Qty: {item?.quantity}</h6>
                      <p className="" style={{ textAlign: "right" }}>
                        <CurrencyIcon />
                        <span className="mx-1" style={{ fontSize: "18px" }}>
                          {(finalProductPrice).toFixed(2)}
                        </span>
                      </p>
                    </div>
                    <div className="shopping-cart-delete">
                      <button
                        onClick={() => {
                          handleRemoveCartItem(item)
                        }}
                      >
                        <i className="fa fa-times-circle" />
                      </button>
                    </div>
                  </li>
                  {isSecurityDepositEnabled && <li className="single-shopping-cart" key={key}>
                    <div className="shopping-cart-img">
                      <Link to={process.env.PUBLIC_URL + "/product/" + item?.product?.id}>
                        <img
                          alt={"security_deposit"}
                          src={securityDepoThumbnailUrl}
                          className="img-fluid"
                        />
                      </Link>
                    </div>
                    <div className="shopping-cart-title">
                      <h4>
                        <Link
                          to={process.env.PUBLIC_URL + "/product/" + item?.variant?.product?.id}
                        >
                          {" "}
                          Refundable deposit for rental product
                          {" "}
                        </Link>
                      </h4>
                      {/* {item?.variant ? (
                      <div className="cart-item-variation">
                        <span>
                          {item?.variant?.title}
                        </span>
                      </div>
                    ) : (
                      ""
                    )} */}
                      <h6>Qty: {1}</h6>
                      <p className="" style={{ textAlign: "right" }}>
                        <CurrencyIcon />
                        <span className="mx-1" style={{ fontSize: "18px" }}>
                          {(securityDepositPrice).toFixed(2)}
                        </span>
                      </p>
                    </div>
                    <div className="shopping-cart-delete">
                      {/* <button
                        onClick={() => {
                          handleRemoveCartItem(item)
                        }}
                      >
                        <i className="fa fa-times-circle" />
                      </button> */}
                    </div>
                  </li>}
                </Fragment>
              }
              else return (
                <li className="single-shopping-cart" key={key}>
                  <div className="shopping-cart-img">
                    <Link to={process.env.PUBLIC_URL + "/product/" + item?.product?.id}>
                      <img
                        alt={item?.variant?.product?.title}
                        src={item?.variant?.product?.thumbnail}
                        className="img-fluid"
                      />
                    </Link>
                  </div>
                  <div className="shopping-cart-title">
                    <h4>
                      <Link
                        to={process.env.PUBLIC_URL + "/product/" + item?.variant?.product?.id}
                      >
                        {" "}
                        {item?.variant?.product?.title}
                        {" "}
                      </Link>
                    </h4>
                    {/* {item?.variant ? (
                      <div className="cart-item-variation">
                        <span>
                          {item?.variant?.title}
                        </span>
                      </div>
                    ) : (
                      ""
                    )} */}
                    <h6>Qty: {item?.quantity}</h6>
                    <p className="" style={{ textAlign: "right" }}>
                      <CurrencyIcon />
                      <span className="mx-1" style={{ fontSize: "18px" }}>
                        {Number(itemTotal).toFixed(2)}
                      </span>
                    </p>
                  </div>
                  <div className="shopping-cart-delete">
                    <button
                      onClick={() => {
                        handleRemoveCartItem(item)
                      }}
                    >
                      <i className="fa fa-times-circle" />
                    </button>
                  </div>
                </li>
              );
            })}
          </ul>
          {/* <div className="shopping-cart-total">
            <h4>
              Total :{" "}
              <span className="shop-total">
                {currency.currencySymbol + cartTotalPrice.toFixed(2)}
              </span>
            </h4>
          </div> */}
          <div className="shopping-cart-btn btn-hover text-center">
            <Link className="default-btn" to={process.env.PUBLIC_URL + "/cart"}>
              view cart
            </Link>
            <Link
              className="default-btn"
              to={process.env.PUBLIC_URL + "/checkout"}
            >
              checkout {swapActive ? "exchange" : ""}
            </Link>
          </div>
        </Fragment>
      ) : (
        <p className="text-center">No items added to cart</p>
      )}
    </div>
  );
};

export default MenuCart;
