import React, { Suspense, lazy, useState, useEffect } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// custom
import { MedusaProvider } from "./context/medusa/MedusaContext";
import "vanilla-cookieconsent/dist/cookieconsent.css";
import CookieConsentComponent from "./components/cookie/CookieConsentComponent";
import { StockLocationProvider } from "./context/stock-location/StockLocationContext";
//import BlogIreland from "./pages/blog/BlogIreland";

import BlogStandardDynamic from "./pages/blog/BlogStandardDynamic";
import { useCMS } from "./context/CMS/cmsContext";

import Page from "./pages/dynamic-pages/Page"
import axios from "axios";
import { server_url } from "./environmentVariables/env";
import { useMedusa } from "./context/medusa/MedusaContext";
import GoogleReCaptchaWrapper from "./wrappers/google-captcha/GoogleReCaptchaWrapper";

// home pages
const HomeFashion = lazy(() => import("./pages/home/HomeFashion"));

const MyOrders = lazy(() => import("./pages/other/MyOrders"))
const Order = lazy(() => import("./pages/other/Order"));
// const HomeFashionTwo = lazy(() => import("./pages/home/HomeFashionTwo"));
// const HomeFashionThree = lazy(() => import("./pages/home/HomeFashionThree"));
// const HomeFashionFour = lazy(() => import("./pages/home/HomeFashionFour"));
// const HomeFashionFive = lazy(() => import("./pages/home/HomeFashionFive"));
// const HomeFashionSix = lazy(() => import("./pages/home/HomeFashionSix"));
// const HomeFashionSeven = lazy(() => import("./pages/home/HomeFashionSeven"));
// const HomeFashionEight = lazy(() => import("./pages/home/HomeFashionEight"));
// const HomeKidsFashion = lazy(() => import("./pages/home/HomeKidsFashion"));
// const HomeCosmetics = lazy(() => import("./pages/home/HomeCosmetics"));
// const HomeFurniture = lazy(() => import("./pages/home/HomeFurniture"));
// const HomeFurnitureTwo = lazy(() => import("./pages/home/HomeFurnitureTwo"));
// const HomeFurnitureThree = lazy(() =>
//   import("./pages/home/HomeFurnitureThree")
// );
// const HomeFurnitureFour = lazy(() => import("./pages/home/HomeFurnitureFour"));
// const HomeFurnitureFive = lazy(() => import("./pages/home/HomeFurnitureFive"));
// const HomeFurnitureSix = lazy(() => import("./pages/home/HomeFurnitureSix"));
// const HomeFurnitureSeven = lazy(() =>
//   import("./pages/home/HomeFurnitureSeven")
// );
// const HomeElectronics = lazy(() => import("./pages/home/HomeElectronics"));
// const HomeElectronicsTwo = lazy(() =>
//   import("./pages/home/HomeElectronicsTwo")
// );
// const HomeElectronicsThree = lazy(() =>
//   import("./pages/home/HomeElectronicsThree")
// );
// const HomeBookStore = lazy(() => import("./pages/home/HomeBookStore"));
// const HomeBookStoreTwo = lazy(() => import("./pages/home/HomeBookStoreTwo"));
// const HomePlants = lazy(() => import("./pages/home/HomePlants"));
// const HomeFlowerShop = lazy(() => import("./pages/home/HomeFlowerShop"));
// const HomeFlowerShopTwo = lazy(() => import("./pages/home/HomeFlowerShopTwo"));
// const HomeOrganicFood = lazy(() => import("./pages/home/HomeOrganicFood"));
// const HomeOrganicFoodTwo = lazy(() =>
//   import("./pages/home/HomeOrganicFoodTwo")
// );
// const HomeOnepageScroll = lazy(() => import("./pages/home/HomeOnepageScroll"));
// const HomeGridBanner = lazy(() => import("./pages/home/HomeGridBanner"));
// const HomeAutoParts = lazy(() => import("./pages/home/HomeAutoParts"));
// const HomeCakeShop = lazy(() => import("./pages/home/HomeCakeShop"));
// const HomeHandmade = lazy(() => import("./pages/home/HomeHandmade"));
// const HomePetFood = lazy(() => import("./pages/home/HomePetFood"));
// const HomeMedicalEquipment = lazy(() =>
//   import("./pages/home/HomeMedicalEquipment")
// );
// const HomeChristmas = lazy(() => import("./pages/home/HomeChristmas"));
// const HomeBlackFriday = lazy(() => import("./pages/home/HomeBlackFriday"));
// const HomeBlackFridayTwo = lazy(() =>
//   import("./pages/home/HomeBlackFridayTwo")
// );
// const HomeValentinesDay = lazy(() => import("./pages/home/HomeValentinesDay"));

// shop pages
const ShopGridStandard = lazy(() => import("./pages/shop/ShopGridStandard"));
// const ShopGridFilter = lazy(() => import("./pages/shop/ShopGridFilter"));
// const ShopGridTwoColumn = lazy(() => import("./pages/shop/ShopGridTwoColumn"));
// const ShopGridNoSidebar = lazy(() => import("./pages/shop/ShopGridNoSidebar"));
// const ShopGridFullWidth = lazy(() => import("./pages/shop/ShopGridFullWidth"));
// const ShopGridRightSidebar = lazy(() =>
//   import("./pages/shop/ShopGridRightSidebar")
// );
// const ShopListStandard = lazy(() => import("./pages/shop/ShopListStandard"));
// const ShopListFullWidth = lazy(() => import("./pages/shop/ShopListFullWidth"));
// const ShopListTwoColumn = lazy(() => import("./pages/shop/ShopListTwoColumn"));

// product pages
const Product = lazy(() => import("./pages/shop-product/Product"));
// const ProductTabLeft = lazy(() =>
//   import("./pages/shop-product/ProductTabLeft")
// );
// const ProductTabRight = lazy(() =>
//   import("./pages/shop-product/ProductTabRight")
// );
// const ProductSticky = lazy(() => import("./pages/shop-product/ProductSticky"));
// const ProductSlider = lazy(() => import("./pages/shop-product/ProductSlider"));
// const ProductFixedImage = lazy(() =>
//   import("./pages/shop-product/ProductFixedImage")
// );

const PasswordReset = lazy(() => import("./pages/other/PasswordReset"));

// blog pages
const BlogStandard = lazy(() => import("./pages/blog/BlogStandard"));
const BlogNoSidebar = lazy(() => import("./pages/blog/BlogNoSidebar"));
const BlogRightSidebar = lazy(() => import("./pages/blog/BlogRightSidebar"));
const BlogIreland = lazy(() => import("./pages/blog/BlogIreland"));
const BlogBenefits = lazy(() => import("./pages/blog/BlogBenefits"));
const BlogLatching = lazy(() => import("./pages/blog/BlogLatching"));
const BlogRights = lazy(() => import("./pages/blog/BlogRights"));
const BlogArdo = lazy(() => import("./pages/blog/BlogArdo"));
const BlogSleep = lazy(() => import("./pages/blog/BlogSleep"));

// other pages
const About = lazy(() => import("./pages/other/About"));
const Contact = lazy(() => import("./pages/other/Contact"));
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const LoginRegister = lazy(() => import("./pages/other/LoginRegister"));
const Terms = lazy(() => import("./pages/other/Terms"));
const Payments = lazy(() => import("./pages/other/Payments"));
const Privacy = lazy(() => import("./pages/other/Privacy"));
const Process = lazy(() => import("./pages/other/Process"));
const Delivery = lazy(() => import("./pages/other/Delivery"));
const Returns = lazy(() => import("./pages/other/Returns"));
const Care = lazy(() => import("./pages/other/Care"));
const Checklist = lazy(() => import("./pages/other/Checklist"));

const Cart = lazy(() => import("./pages/other/Cart"));
const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const Compare = lazy(() => import("./pages/other/Compare"));
const Checkout = lazy(() => import("./pages/other/Checkout"));
const OrderReturns = lazy(() => import("./pages/other/OrderReturns"));
const NotFound = lazy(() => import("./pages/other/NotFound"));
const Thankyou = lazy(() => import("./pages/other/Thanks"))

//Store locations
const StoreLocations = lazy(() =>
  import("./pages/store-location/StoreLocations")
);

const App = () => {
  const [showCookiePolicy, setShowCookiePolicy] = useState(false);

  // Import CMS data
  const { blogs, pages } = useCMS();

  const {
    user
  } = useMedusa();

  useEffect(() => {
    setTimeout(() => {
      setShowCookiePolicy(true);
    }, 3000);
  }, []);

  useEffect(() => {
    // const body = document.getElementsByTagName("body");

    let htmlString = `
      <div class="overlay-1">
      <div class="container-1">
          <h3 class="text-bold text-center" style="margin-top: 30px;">Site closed for relaunch</h3>
          <div style="margin-top: 10px; margin-bottom: 10px">
              <img class="brand-icon" src="http://admin.breastisbest.ie/assets/payload-breastisbest.jpg" alt="Brand Icon" />
          </div>
          <h5 class="text-bold text-center"> For pump rentals, please call our office </h5>
          <h3 class="text-bold text-center add-theme-color"> 9:30 - 6PM on (01) 443 8841 </h3>
          <h5 class="text-bold text-center" style="margin-bottom: 30px;"> Closed December 25th </h5>
      </div>
      </div>
    `;

    // document.body.insertAdjacentHTML('beforeend', htmlString);
  }, []);

  return (
    <MedusaProvider>
      <StockLocationProvider>
        <GoogleReCaptchaWrapper>
          <Router>
            <ScrollToTop>
              <Suspense
                fallback={
                  <div className="flone-preloader-wrapper">
                    <div className="flone-preloader">
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                }
              >
                <Routes>
                  <Route
                    path={process.env.PUBLIC_URL + "/"}
                    element={<HomeFashion />}
                  />

                  <Route
                    path={process.env.PUBLIC_URL + "/my-orders"}
                    element={<MyOrders />}
                  />

                  <Route
                    path={process.env.PUBLIC_URL + "/order/:id"}
                    element={<Order />}
                  />

                  <Route
                    path={process.env.PUBLIC_URL + "/order/raise-returns/:id"}
                    element={<OrderReturns />}
                  />

                  <Route
                    path={process.env.PUBLIC_URL + "/order/order-thanks/:id/*"}
                    element={<Thankyou />}
                  />

                  {/* Homepages */}
                  {/* <Route
                  path={process.env.PUBLIC_URL + "/home-fashion"}
                  element={<HomeFashion />}
                /> */}
                  {/* <Route
                path={process.env.PUBLIC_URL + "/home-fashion-two"}
                element={<HomeFashionTwo/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-fashion-three"}
                element={<HomeFashionThree/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-fashion-four"}
                element={<HomeFashionFour/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-fashion-five"}
                element={<HomeFashionFive/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-fashion-six"}
                element={<HomeFashionSix/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-fashion-seven"}
                element={<HomeFashionSeven/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-fashion-eight"}
                element={<HomeFashionEight/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-kids-fashion"}
                element={<HomeKidsFashion/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-cosmetics"}
                element={<HomeCosmetics/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-furniture"}
                element={<HomeFurniture/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-furniture-two"}
                element={<HomeFurnitureTwo/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-furniture-three"}
                element={<HomeFurnitureThree/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-furniture-four"}
                element={<HomeFurnitureFour/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-furniture-five"}
                element={<HomeFurnitureFive/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-furniture-six"}
                element={<HomeFurnitureSix/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-furniture-seven"}
                element={<HomeFurnitureSeven/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-electronics"}
                element={<HomeElectronics/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-electronics-two"}
                element={<HomeElectronicsTwo/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-electronics-three"}
                element={<HomeElectronicsThree/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-book-store"}
                element={<HomeBookStore/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-book-store-two"}
                element={<HomeBookStoreTwo/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-plants"}
                element={<HomePlants/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-flower-shop"}
                element={<HomeFlowerShop/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-flower-shop-two"}
                element={<HomeFlowerShopTwo/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-organic-food"}
                element={<HomeOrganicFood/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-organic-food-two"}
                element={<HomeOrganicFoodTwo/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-onepage-scroll"}
                element={<HomeOnepageScroll/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-grid-banner"}
                element={<HomeGridBanner/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-auto-parts"}
                element={<HomeAutoParts/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-cake-shop"}
                element={<HomeCakeShop/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-handmade"}
                element={<HomeHandmade/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-pet-food"}
                element={<HomePetFood/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-medical-equipment"}
                element={<HomeMedicalEquipment/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-christmas"}
                element={<HomeChristmas/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-black-friday"}
                element={<HomeBlackFriday/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-black-friday-two"}
                element={<HomeBlackFridayTwo/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-valentines-day"}
                element={<HomeValentinesDay/>}
              /> */}

                  {/* Shop pages */}
                  <Route
                    path={process.env.PUBLIC_URL + "/shop-grid-standard"}
                    element={<ShopGridStandard />}
                  />
                  {/* <Route
                path={process.env.PUBLIC_URL + "/shop-grid-filter"}
                element={<ShopGridFilter/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/shop-grid-two-column"}
                element={<ShopGridTwoColumn/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/shop-grid-no-sidebar"}
                element={<ShopGridNoSidebar/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/shop-grid-full-width"}
                element={<ShopGridFullWidth/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/shop-grid-right-sidebar"}
                element={<ShopGridRightSidebar/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/shop-list-standard"}
                element={<ShopListStandard/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/shop-list-full-width"}
                element={<ShopListFullWidth/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/shop-list-two-column"}
                element={<ShopListTwoColumn/>}
              /> */}

                  {/* Shop product pages */}
                  <Route
                    path={process.env.PUBLIC_URL + "/product/:id"}
                    element={<Product />}
                  />
                  {/* <Route
                path={process.env.PUBLIC_URL + "/product-tab-left/:id"}
                element={<ProductTabLeft/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/product-tab-right/:id"}
                element={<ProductTabRight/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/product-sticky/:id"}
                element={<ProductSticky/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/product-slider/:id"}
                element={<ProductSlider/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/product-fixed-image/:id"}
                element={<ProductFixedImage/>}
              />  */}

                  {/* Blog pages */}
                  {blogs.length > 0 && blogs.map((blog, idx) => (
                    <Route
                      key={idx}
                      path={process.env.PUBLIC_URL + blog.slug_url}
                      element={<BlogStandardDynamic blogs={blogs} blog={blog} />}
                    />
                  ))}
                  {pages.length > 0 && pages.map((page, idx) => (
                    <Route
                      key={idx}
                      path={process.env.PUBLIC_URL + page.slug_url}
                      element={<Page page={page} />}
                    />
                  ))}

                  <Route
                    path={process.env.PUBLIC_URL + "/blog-standard"}
                    element={<BlogStandard />}
                  />

                  {/* Dynamic Pages */}
                  {/* {pages.length > 0 && pages.map((page, idx) => (
                    <Route
                      key={idx}
                      path={process.env.PUBLIC_URL + page.slug_url}
                      element={<Page page={page} />}
                    />
                  ))} */}

                  {/* <Route
                  path={process.env.PUBLIC_URL + "/blog-ardo"}
                  element={<BlogArdo />}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/blog-benefits"}
                  element={<BlogBenefits />}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/blog-latching"}
                  element={<BlogLatching />}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/blog-rights"}
                  element={<BlogRights />}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/blog-no-sidebar"}
                  element={<BlogNoSidebar />}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/blog-sleep"}
                  element={<BlogSleep />}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/blog-right-sidebar"}
                  element={<BlogRightSidebar />}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/blog-ireland"}
                  element={<BlogIreland />}
                /> */}

                  {/* Other pages */}
                  <Route
                    path={process.env.PUBLIC_URL + "/about"}
                    element={<About />}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/contact"}
                    element={<Contact />}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/my-account"}
                    element={<MyAccount />}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/login-register/:state/*"}
                    element={<LoginRegister />}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/reset-password/:code"}
                    element={<PasswordReset />}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/terms"}
                    element={<Terms />}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/privacy"}
                    element={<Privacy />}
                  />
                  {/* <Route
                    path={process.env.PUBLIC_URL + "/care"}
                    element={<Care />}
                  /> */}
                  {/* <Route
                    path={process.env.PUBLIC_URL + "/checklist"}
                    element={<Checklist />}
                  /> */}
                  <Route
                    path={process.env.PUBLIC_URL + "/payments"}
                    element={<Payments />}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/returns"}
                    element={<Returns />}
                  />
                  {/* <Route
                    path={process.env.PUBLIC_URL + "/process"}
                    element={<Process />}
                  /> */}
                  {/* <Route
                    path={process.env.PUBLIC_URL + "/delivery"}
                    element={<Delivery />}
                  /> */}

                  <Route
                    path={process.env.PUBLIC_URL + "/cart"}
                    element={<Cart />}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/wishlist"}
                    element={<Wishlist />}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/compare"}
                    element={<Compare />}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/checkout"}
                    element={<Checkout />}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/store-locations"}
                    element={<StoreLocations />}
                  />
                  <Route path="*" element={<NotFound />} />
                </Routes>

                <CookieConsentComponent />
              </Suspense>
            </ScrollToTop>
          </Router>
        </GoogleReCaptchaWrapper>
      </StockLocationProvider>
    </MedusaProvider>
  );
};


export default App;
